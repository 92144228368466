// Custom styles for this theme
.header {
  --cui-header-min-height: 5rem;
  --cui-header-border: 0;
}
.header > .container-fluid,
.sidebar-header {
  //  min-height: calc(7rem + 0px); // stylelint-disable-line function-disallowed-list
  min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
}

.header > .container-fluid + .container-fluid {
  min-height: 0rem;
}
.custom-card-body {
  margin: 0;
  padding: 0;
}

.sidebar-brand-full {
  margin-left: 3px;
}

.sidebar-toggler {
  @include ltr-rtl('margin-left', auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-toggler {
    @include ltr-rtl('margin-right', auto);
  }
}

.footer {
  min-height: calc(3rem + 1px); // stylelint-disable-line function-disallowed-list
}

.grid-force {
  display: grid !important;
}

// .MuiTablePagination-spacer .css-1psng7p-MuiTablePagination-spacer {
// }
